import React from 'react';
import {Styles} from '../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import RichEditor from "../../components/editor/RichEditor";
import TextField from "@material-ui/core/TextField/TextField";
import validator from "validator";
import axios from "axios";
import {defaultVariabls} from "../../../config/index";
import {withRouter} from "react-router-dom";
import { withSnackbar } from 'notistack';
import LoadingButton from "../../components/elements/LoadingButton";
import SubTitle from "../../components/elements/SubTitle";
import AppAnimate from "../../components/AppAnimate/index";
import AppCard from "../../components/layouts/components/AppCard";
import Grid from "@material-ui/core/Grid/Grid";
import Typography from "@material-ui/core/Typography/Typography";
import {connect, setStore} from "trim-redux";
import BreadCrumbs from "../../components/elements/BreadCrumbs";
import AppCardLoaderFields from "../../components/Skeletons/AppCardLoaderFields";
import Alert from "@material-ui/lab/Alert/Alert";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Switch from "@material-ui/core/Switch/Switch";
import LinearCategoryHolder from "../../components/fields/LinearCategoryHolder/index";
import ImageGallery from "../fileManager/Components/ImageGallery";
import Button from "@material-ui/core/Button/Button";
import PageviewRoundedIcon from '@material-ui/icons/PageviewRounded';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import CurrencyTextField from "@unicef/material-ui-currency-textfield";
import GetConfirmationText from "../../components/elements/GetConfirmationText";
import moment from "moment-timezone";
import {makeSlug, translate} from "../../../config/lang";
import CurrencyChooser from "../../components/fields/CurrencyChooser";
import OptionUnitChooser from "../../components/fields/OptionUnitChooser";
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";
import FieldCounterWithoutBar from "../../components/elements/FieldCounterWithoutBar";
import UserCard from "../../components/elements/UserCard";
import SingleImageChooser from "../fileManager/Components/SingleImageChooser";
import SelectLocationFromMap from "../../components/map/SelectLocationFromMap";
import CountryChooser from "../../components/fields/CountryChooser";
import Divider from "@material-ui/core/Divider/Divider";
import {hasAccess, updateRedux} from "../../../config/Security";
import YourPlanDoesntSupportThis from "../Dashboard/components/YourPlanDoesntSupportThis";
import StarRateRoundedIcon from '@material-ui/icons/StarRateRounded';
import ViewRelatedComments from "../admin/comments/components/ViewRelatedComments";
import CheckUserCanAdProduct from "./components/CheckUserCanAdProduct";
import MakeEqualProduct from "./MakeEqualProduct";
import CustomFields from "../advertisements/components/CustomFields";
import FilesChooser from "../fileManager/Components/FilesChooser";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import FormControl from "@material-ui/core/FormControl/FormControl";
import Rating from "@material-ui/lab/Rating/Rating";
import CheckUserCanAdProductBasedOnBusiness from "./components/CheckUserCanAdProductBasedOnBusiness";
import BusinessUserChooser from "../businesses/components/BusinessUserChooser";
import ProductUserChooser from "./components/ProductUserChooser";
import {getTimeZone} from "../../../config/values";
import {InputLabel, ListItemText, MenuItem} from "@material-ui/core";
import Select from "@material-ui/core/Select";

class MakeProduct extends React.Component {
    constructor(props){
        super(props);
        this.ThumbChild = React.createRef();
        this.EditorChild = React.createRef();
    }
    state = {
        fields:{
            name:"",
            description:"",
            meta_title:"",
            company_address:"",
            depot_address:"",
            price:'',
            priceR:'',
            discount_price:'',
            discount_priceR:'',
            moq:'',
            payment_method:[],
        },
        content:{
            HTML: '',
            RAW: null,
            PlainText: ''
        },
        thumbnail: null,
        leadtime: null,
        errors:{},
        categories:[],
        categoriesName:[],
        related_user:null,
        gallery: [],
        examples: [],
        files: [],
        loading: true,
        currency: '',
        moq_unit: '',
        price_unit: '',
        has_depot_address:false,
        SubmitLoading: false,
        allowed_comment: 0,
        is_popular: 0,
        has_sample: 0,
        verified_sample: 0,
        average_rating: 0,
        no_index:0,
        publish: true,
        confirmed: false,
        real_confirmed: false,
        slug: null,
        id:this.props.match.params.id,
        editMode:false,
        owner_id:null,
        owner:null,
        requested_to_delete:0,
        editor_input_text:'',
        updated_at:null,
        created_at:null,
        expired_at:null,
        expired:0,
        expired_this_week:0,
        activePhoneNumbers:1,
        phoneNumberFilled:false,
        activeEmail:1,
        emailFilled:false,
        company_lat:"",
        company_lng:"",
        depot_lat:"",
        depot_lng:"",
        origin:null,
        server_origin:null,
        visits:0,
        today_visits:0,
        comments:0,
        lang: this.props.locale,
        parent_category:null,
        custom_fields:null,
        item:null,
        equal:null,
        product_details:null,
        essential_details:null,
        packaging_delivery:null,
        supply_ability:null,
    };
    classes = this.props.classes;
    componentDidMount(){
        if(this.state.id){
            this.setState({
                editMode:true
            });
            this.loadData();
        }else{
            this.setState({
                loading:false
            })
        }
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
    }
    componentDidUpdate(prevProps){
        if(this.state.id !== this.props.match.params.id){
            this.setState({
                id: this.props.match.params.id
            })
        }
    }
    handleThumbImage(thumbnail){
        this.setState({
            thumbnail
        })
    }
    handleLeadImage(leadtime){
        this.setState({
            leadtime
        })
    }
    async loadData(){
        this.setState({
            loading:true
        })
        let loginToken = localStorage.getItem('ACT');
        if(loginToken !== null){
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            await axios.get(`${defaultVariabls.url}/product/get/${this.state.id}`, config)
                .then(response => {
                    this.setState({
                        fields: {
                            name: response.data.name,
                            description:!!response.data.description ? response.data.description : '',
                            meta_title:!!response.data.meta_title ? response.data.meta_title : '',
                            company_address:!!response.data.company_address ? response.data.company_address : '',
                            depot_address:!!response.data.depot_address ? response.data.depot_address : '',
                            price:!!response.data.price ? response.data.price : '',
                            priceR:!!response.data.price ? response.data.price : '',
                            discount_price:!!response.data.discount_price ? response.data.discount_price : '',
                            discount_priceR:!!response.data.discount_price ? response.data.discount_price : '',
                            moq:!!response.data.moq ? response.data.moq : '',
                            payment_method:!!response.data.payment_method ? response.data.payment_method.split(',') : [],
                        },
                        thumbnail: response.data.thumbnail,
                        average_rating: response.data.average_rating,
                        leadtime: response.data.leadtime,
                        content: {
                            HTML: response.data.html,
                            RAW: response.data.raw,
                        },
                        confirmed: response.data.confirmed,
                        no_index: response.data.no_index,
                        real_confirmed: response.data.confirmed,
                        slug: response.data.slug,
                        currency: response.data.currency,
                        moq_unit: response.data.moq_unit,
                        price_unit: response.data.price_unit,
                        owner_id: response.data.user_id,
                        owner: response.data.owner,
                        updated_at: response.data.updated_at,
                        created_at: response.data.created_at,
                        expired_at: response.data.expired_at,
                        company_lat:!!response.data.company_lat ? parseFloat(response.data.company_lat) : '',
                        company_lng:!!response.data.company_lng ?  parseFloat(response.data.company_lng) : '',
                        depot_lat:!!response.data.depot_lat ?  parseFloat(response.data.depot_lat) : '',
                        depot_lng:!!response.data.depot_lng ?  parseFloat(response.data.depot_lng) : '',
                        has_depot_address:!!response.data.depot_lng,
                        origin:!!response.data.origin ? response.data.origin : null,
                        server_origin:!!response.data.origin ? response.data.origin : null,
                        visits:parseInt(response.data.visits_count),
                        today_visits:parseInt(response.data.today_visits_count),
                        comments:parseInt(response.data.comments_count),
                        lang:response.data.lang,
                        product_details:response.data.product_details,
                        essential_details:response.data.essential_details,
                        packaging_delivery:response.data.packaging_delivery,
                        supply_ability:response.data.supply_ability,
                        allowed_comment:parseInt(response.data.allowed_comment),
                        is_popular:parseInt(response.data.is_popular),
                        has_sample:parseInt(response.data.has_sample),
                        verified_sample:parseInt(response.data.verified_sample),

                    })
                    let categoriesTemp=[];
                    let categoriesNameTemp=[];
                    let galleryTemp=[];
                    let examplesTemp=[];
                    let filesTemp=[];
                    let parent_category = null;
                    response.data.categories.map(item => {
                        if(!parent_category){
                            parent_category = item.id;
                        }
                        categoriesTemp.push(item.id)
                        categoriesNameTemp.push(item.name)
                        return true
                    })
                    response.data.gallery.map(item =>{
                        galleryTemp.push({
                            src:`${defaultVariabls.uploads}/FileManager/${item.name}_s_thumb.${item.extension}`,
                            link:!!item.source_id ? `https://www.youtube.com/watch?v=${item.source_id}` : `${defaultVariabls.uploads}/FileManager/${item.name}.${item.extension}`,
                            id: item.id,
                            name: item.name,
                            type: item.type,
                            preview: item.preview_url ? item.preview_url : item.preview_id ? `${defaultVariabls.uploads}/FileManager/${item.preview.name}_s_thumb.${item.preview.extension}` : '',
                        })
                        return true
                    })
                    response.data.examples.map(item =>{
                        examplesTemp.push({
                            src:`${defaultVariabls.uploads}/FileManager/${item.name}_s_thumb.${item.extension}`,
                            link:!!item.source_id ? `https://www.youtube.com/watch?v=${item.source_id}` : `${defaultVariabls.uploads}/FileManager/${item.name}.${item.extension}`,
                            id: item.id,
                            name: item.name,
                            type: item.type,
                            preview: item.preview_url ? item.preview_url : item.preview_id ? `${defaultVariabls.uploads}/FileManager/${item.preview.name}_s_thumb.${item.preview.extension}` : '',
                        })
                        return true
                    })
                    response.data.attachments.map(item =>{
                        filesTemp.push({
                            id: item.id,
                            name: item.name,
                            extension: item.extension,
                            type: item.type,
                        })
                        return true
                    })

                    this.setState({
                        categories: categoriesTemp,
                        categoriesName: categoriesNameTemp,
                        gallery: galleryTemp,
                        examples: examplesTemp,
                        files: filesTemp,
                        parent_category,
                        item:response.data,
                        equal:response.data.equal,
                        loading: false
                    })
                }).catch(error => {
                    console.log(error)
                    /*this.props.history.push('/');
                    this.props.enqueueSnackbar(translate('pageNotFound'), {
                        variant: 'error',
                    });*/
                    this.setState({
                        loading:false
                    })
                })
        }else {
            setStore({
                isAuth : false,
                user : null,
            });
            this.setState({
                loading:false
            })
        }
    }
    handleChange(e){
        let {errors,fields} = this.state;
        let target = e.target;
        if(target.name === "price"){
            fields.priceR = target.value.replace(/,/ig, '');
        }else if(target.name === "discount_price"){
            fields.discount_priceR = target.value.replace(/,/ig, '');
        }else{
            fields[target.name] = target.value;
        }
        errors[target.name] = "";
        this.setState({
            fields,
            errors
        });
    }

    handleValidation(callback){
        let { fields,company_lat,depot_lat,categories } = this.state;
        let errors = {};
        let formIsValid = true;

        //name
        if(validator.isEmpty(fields.name)){
            formIsValid = false;
            errors['name'] = translate('emptyField');
        }
        if(!!fields.priceR && !!fields.discount_priceR){
            if(parseInt(fields.priceR) < parseInt(fields.discount_priceR)){
                formIsValid = false;
                errors['price'] = translate('priceIsLessThanDiscount');
            }
        }
        if(categories.length === 0){
            formIsValid = false;
            errors['category'] = translate('emptyField');
        }
        //Description
        if(fields.description !== null ) {
            if (fields.description.length > 150) {
                formIsValid = false;
                errors['description'] = translate('maxLength150');
            }
        }
        //address
        if(fields.company_address !== null ) {
            if (fields.company_address.length > 150) {
                formIsValid = false;
                errors['company_address'] = translate('maxLength150');
            }
        }
        //address
        if(fields.depot_address !== null ) {
            if (fields.depot_address.length > 150) {
                formIsValid = false;
                errors['depot_address'] = translate('maxLength150');
            }
        }


        if(!formIsValid){
            this.props.enqueueSnackbar(translate('someFieldsHasError'), {
                variant: 'warning',
            });
            this.setState({
                errors,
                SubmitLoading:false,
            })
        }
        return callback(formIsValid)
    }
    handleRequest(){
        let { id,fields,content,no_index,thumbnail,leadtime,related_user,average_rating,files,moq_unit,price_unit,allowed_comment,verified_sample,is_popular,has_sample,editMode,gallery,examples,categories,confirmed,currency,company_lat,company_lng,depot_lat,depot_lng,origin} = this.state;
        let data = new FormData();
        data.append('name', fields.name);
        data.append('moq', fields.moq);
        data.append('payment_method', fields.payment_method);
        data.append('moq_unit', moq_unit);
        data.append('price_unit', price_unit);
        data.append('lang', this.props.locale);
        data.append('html', content.HTML);
        data.append('raw', content.RAW);
        data.append('related_user', related_user ? related_user.id : 0);
        data.append('plainText', content.PlainText);
        data.append('average_rating', average_rating);
        data.append('id', id ? id : 0);
        data.append('description', fields.description);
        data.append('meta_title', fields.meta_title);
        data.append('company_address', fields.company_address);
        data.append('depot_address', fields.depot_address);
        data.append('thumbnail', !!thumbnail ? thumbnail.id : 1);
        data.append('leadtime', !!leadtime ? leadtime.id : '');
        data.append('currency', currency);
        data.append('confirmed',confirmed ? 1 : 0);
        data.append('price', fields.priceR);
        data.append('discount_price', fields.discount_priceR);
        data.append('company_lat', company_lat);
        data.append('company_lng', company_lng);
        data.append('depot_lat', depot_lat);
        data.append('depot_lng', depot_lng);
        data.append('origin_id', !!origin ? origin.id : '');
        data.append('product_details', JSON.stringify(this.state.product_details));
        data.append('essential_details', JSON.stringify(this.state.essential_details));
        data.append('supply_ability', JSON.stringify(this.state.supply_ability));
        data.append('packaging_delivery', JSON.stringify(this.state.packaging_delivery));
        data.append('allowed_comment', parseInt(allowed_comment) );
        data.append('is_popular', parseInt(is_popular) );
        data.append('has_sample', parseInt(has_sample) );
        data.append('verified_sample', parseInt(verified_sample) );
        data.append('no_index',  !!no_index ? 1 : 0);
        data.append('categories', JSON.stringify(categories));

        if(gallery !== null){
            let galery_ids = [];
            gallery.map((row) =>{
                galery_ids.push(row.id);
                return true
            })
            gallery = JSON.stringify(galery_ids);
        }
        data.append('gallery', gallery);

        if(examples !== null){
            let examples_ids = [];
            examples.map((row) =>{
                examples_ids.push(row.id);
                return true
            })
            examples = JSON.stringify(examples_ids);
        }
        data.append('examples', examples);

        if(files !== null){
            let files_ids = [];
            files.map((row) =>{
                files_ids.push(row.id);
                return true
            })
            files = JSON.stringify(files_ids);
        }
        data.append('attachments', files);
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        let {user} = this.props;
        axios.post(`${defaultVariabls.url}/product/make`, data, config)
            .then(response => {
                this.props.enqueueSnackbar(editMode ? translate('productEdited') : translate('productAdded'), {
                    variant: 'success',
                });
                this.setState({
                    loading:false,
                    SubmitLoading: false,
                });
                updateRedux();
                if(!!user.role.permission.manageAdvertisements){
                    window.scrollTo({
                        top: 0,
                        behavior: "smooth"
                    })
                    this.props.history.push(`/manage/products`);
                }else{
                    window.scrollTo({
                        top: 0,
                        behavior: "smooth"
                    })
                    this.props.history.push(`/my-products`);
                }
                if(!this.state.editMode){

                }else{
                    this.loadData();
                }
            })
            .catch(error => {
                console.log(error.response)
                if(error.response.status === 403){
                    this.props.enqueueSnackbar(translate('duplicateProductName'), {
                        variant: 'warning',
                    });
                }else{
                    this.props.enqueueSnackbar(editMode ?  translate('productEditingFailed') : translate('productAddingFailed'), {
                        variant: 'error',
                    });
                }


                this.setState({
                    loading:false,
                    SubmitLoading: false,
                });
            });

    }
    handleClickDescription(){
        let {fields,content} = this.state;
        if(fields['description'] === ''){
            fields['description'] = content.PlainText === null ? null : content.PlainText.slice(0, 150);
            this.setState({fields})
        }
    }
    handleSubmit(e,draft = false){
        this.setState({
            SubmitLoading: true
        })
        e.preventDefault();
        this.handleValidation((valid) =>{
            if(valid) {
                this.setState({
                    publish:draft
                })
                this.handleRequest();
            }
        });
    }

    handleOutPut(code){
        this.setState({
            content:code
        })
    }
    handleCategoryHolder(categories){
        let {parent_category,errors} = this.state;
        if(parent_category === null){
            this.setState({
                parent_category: categories[0]
            })
        }else if(parent_category !== categories[0]){
            this.setState({
                parent_category: categories[0]
            })
        }
        errors['category'] ='';
        this.setState({
            categories,
            errors
        })
    }
    handleGalleryChange(data){
        this.setState({
            gallery: data,
        })
    }
    handleExamplesChange(data){
        this.setState({
            examples: data,
        })
    }
    handleChangeConfirmed(){
        this.setState({
            confirmed: !this.state.confirmed,
        })
    }
    async handleRequestToDelete(){
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        await axios.get(`${defaultVariabls.url}/product/request_delete/${this.state.id}`, config)
            .then(response => {
                this.props.enqueueSnackbar(translate('requestedToDelete'), {
                    variant: 'success',
                });
                this.setState({
                    requested_to_delete:1,
                })
            }).catch(error => {
                console.log(error.response)
            })
    }
    handleDeleteItem(){
        let data = new FormData();
        data.append('id', this.state.id);
        let loginToken = localStorage.getItem('ACT');

        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/delete/product`, data, config)
            .then(response => {
                this.props.enqueueSnackbar(translate('deleteProduct'), {
                    variant: 'success',
                });
                this.props.history.push('/manage/products')
                return true
            })

            .catch(error => {
                console.log(error.response)
                this.props.enqueueSnackbar(translate('deleteProductFailed'), {
                    variant: 'error',
                });
                return false
            });
    }
    async changeCategories(){
        await this.setState({
            categoriesName:[],
            categories:[],
            variables:[],
        })
    }
    handleChangeCurrency(currency){
        this.setState({
            currency
        })
    }
    handleChangeUnit(unit){
        this.setState({
            moq_unit:unit
        })
    }
    handleChangePriceUnit(unit){
        this.setState({
            price_unit:unit
        })
    }

    handleCompanyAddress(address){
        let {fields} = this.state;
        fields['company_address'] = address;
        this.setState({
            fields
        })

    }
    handleDepotAddress(address){
        let {fields} = this.state;
        fields['depot_address'] = address;
        this.setState({
            fields
        })

    }
    handleFilesChange(data){
        this.setState({
            files: data,
        })
    }
    handleNoDepotLoc(){
        let{fields} = this.state;
        fields['depot_address'] = '';
        this.setState({
            has_depot_address:false,
            depot_lat:'',
            depot_lng:'',
            fields
        })

    }
    render(){
        let {fields,currency,moq_unit,price_unit,item,equal,files,average_rating,loading,server_country,server_state,phoneNumberFilled,activeEmail,emailFilled, errors,gallery,examples,slug,SubmitLoading,owner,thumbnail,leadtime,editor_input_text,categoriesName,content,editMode,real_confirmed,confirmed,company_lat,company_lng,depot_lat,depot_lng} = this.state;
        const {user} = this.props;
        return (
            <AppAnimate animation='transition.slideUpIn' delay={200} title={editMode ? translate('editProduct') :  translate('makeProduct')}>
                {
                    <Grid container spacing={2} justify="center">
                        <Grid item xs={12} lg={11}>
                            <Grid container spacing={2} justify="center">
                                {
                                    (!loading && user.role.type !== "admin") &&
                                    <CheckUserCanAdProductBasedOnBusiness/>
                                }
                                {
                                    (user.role.type !== "admin" && !loading) &&
                                    <CheckUserCanAdProduct edit={!!editMode}/>
                                }

                                {
                                    (!!editMode && !loading) &&
                                    <>
                                        <Grid item xs={12}>
                                            {
                                                !! user.role.permission.manageAdvertisements ?
                                                    <>
                                                        <Alert
                                                            severity="info"
                                                            action={
                                                                <FormControlLabel
                                                                    style={{marginRight:10}}
                                                                    control={
                                                                        <Switch
                                                                            checked={confirmed}
                                                                            onChange={this.handleChangeConfirmed.bind(this)}
                                                                            value={confirmed}
                                                                            color="primary"
                                                                        />
                                                                    }
                                                                    label={confirmed ? translate('confirmed') : translate('unConfirmed')}
                                                                />
                                                            }
                                                        >
                                                            {translate('confirmationStatusDescription')}
                                                        </Alert>
                                                    </>
                                                    :
                                                    confirmed ?
                                                        <Alert severity="success" variant="filled">
                                                            <Typography  className={this.classes.alertText}>{translate('yourProductIsActive')}</Typography>
                                                        </Alert>
                                                        :
                                                        <Alert severity="warning">
                                                            <Typography className={this.classes.alertText}>{translate('yourProductIsInchecking')}</Typography>
                                                        </Alert>
                                            }
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <AppCard style={{height:"100%"}}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        {
                                                            !!real_confirmed ?
                                                                <>
                                                                    <Button
                                                                        component="a"
                                                                        target="_blank"
                                                                        size="small"
                                                                        startIcon={<PageviewRoundedIcon/>}
                                                                        href={makeSlug(`product/${slug}`,this.state.lang)}
                                                                        color="secondary"
                                                                    >
                                                                        {translate('viewProduct')}
                                                                    </Button>
                                                                </> :
                                                                <>
                                                                    <Button
                                                                        component="a"
                                                                        target="_blank"
                                                                        size="small"
                                                                        variant="contained"
                                                                        startIcon={<PageviewRoundedIcon/>}
                                                                        href={makeSlug(`product/${slug}`,this.state.lang)}
                                                                        color="secondary"
                                                                    >
                                                                        {translate('viewPreview')}
                                                                    </Button>

                                                                </>
                                                        }
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Typography  className='date-holder'>
                                                            <span>{translate('todayVisitsCount')}</span>
                                                            <strong>
                                                                {this.state.today_visits}
                                                            </strong>
                                                        </Typography>
                                                        <Divider style={{marginTop:10,marginBottom:10}}/>
                                                        <Typography  className='date-holder'>
                                                            <span>{translate('totalVisitsCount')}</span>
                                                            <strong>
                                                                {this.state.visits}
                                                            </strong>
                                                        </Typography>
                                                        <Divider style={{marginTop:10,marginBottom:10}}/>
                                                        <Typography  className='date-holder'>
                                                            <span>{translate('totalCommentsCount')} <ViewRelatedComments type={"product"} item_id={this.state.id}/></span>
                                                            <strong>
                                                                {this.state.comments}
                                                            </strong>
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </AppCard>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <AppCard style={{height:"100%"}}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <SubTitle align="left" style={{position:"relative"}}>
                                                            {translate('productInfo')}
                                                        </SubTitle>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Typography  className='date-holder'>
                                                                <span>
                                                                {translate('updated_at')}
                                                            </span>
                                                            <strong>
                                                                {`${moment.tz(this.state.updated_at,'UTC').tz(getTimeZone()).format( 'HH:mm , YYYY/M/D')}`}
                                                            </strong>
                                                        </Typography>
                                                        <Divider style={{marginTop:10,marginBottom:10}}/>
                                                        <Typography  className='date-holder'>
                                                            <span>{translate('created_at')}</span>
                                                            <strong>
                                                                {`${moment.tz(this.state.created_at,'UTC').tz(getTimeZone()).format( 'HH:mm , YYYY/M/D')}`}
                                                            </strong>
                                                        </Typography>
                                                        <Divider style={{marginTop:10,marginBottom:10}}/>

                                                        <Typography  className='date-holder'>
                                                             <span>
                                                                {translate('average_rating')}
                                                            </span>
                                                            <strong className="rating">
                                                                <Rating
                                                                    readOnly={user.role.type !== "admin"}
                                                                    name="rating"
                                                                    precision={0.5}
                                                                    value={average_rating}
                                                                    onChange={(event, newValue) => {
                                                                        this.setState({
                                                                            average_rating:newValue,
                                                                        })
                                                                    }}
                                                                />
                                                            </strong>
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </AppCard>
                                        </Grid>
                                    </>

                                }
                                {
                                    (!!editMode && !loading) &&
                                    <Grid item xs={12} md={4}>
                                        <AppCard style={{height:"100%"}}>
                                            <SubTitle align="left" style={{position:"relative",marginBottom:33}}>
                                                {translate('planInfo')}
                                                {
                                                    user.role.type !== "admin" &&
                                                    <Button
                                                        color="secondary"
                                                        size="small"
                                                        style={{position:'absolute',top:-5,right:0}}
                                                        variant="contained"
                                                        startIcon={<img style={{width:20}} src={`${defaultVariabls.uploads}/FileManager/${user.plan.parent.thumbnail.name}.${user.plan.parent.thumbnail.extension}`}  />}
                                                    >
                                                        {user.plan.parent.name}
                                                    </Button>
                                                }

                                            </SubTitle>

                                            {
                                                user.role.type !== "admin" ?

                                                    <>
                                                        <Typography className='date-holder'>
                                                            <span>{translate('availableProducts')}</span>
                                                            {
                                                                user.plan.make_products === null ?
                                                                    <strong className={this.classes.primary_color}>{translate('infinite')}</strong>
                                                                :
                                                                    <strong className={this.classes.primary_color}>{parseInt(user.make_products) - parseInt(user.plan_usage.make_products)}</strong>

                                                            }
                                                        </Typography>

                                                    </>

                                                    :

                                                    <>
                                                        <Typography className={this.classes.condir}>{translate('adminHasFullAccess')}</Typography>
                                                    </>
                                            }
                                        </AppCard>
                                    </Grid>
                                }

                                {
                                    !!editMode &&
                                    <>
                                        {
                                            this.state.loading ? null :
                                                <Grid item xs={12}>
                                                    <AppCard
                                                        className={this.classes.equalAppCard}
                                                    >
                                                        <div
                                                            className={this.classes.equalHolder}
                                                        >
                                                            <SubTitle noHR align="left">{translate('equalItem')}</SubTitle>

                                                            <MakeEqualProduct
                                                                equal_id={!!equal ? equal.id : null}
                                                                item={item}
                                                                reload={() => this.loadData()}
                                                            />
                                                        </div>
                                                    </AppCard>
                                                </Grid>
                                        }
                                    </>
                                }
                                <Grid item xs={12}>
                                    <AppCard
                                        title={editMode ? translate('editProduct') :  translate('makeProduct')}
                                        action={
                                            <BreadCrumbs singleLevel firstText={editMode ? translate('editProduct') :  translate('makeProduct')}/>
                                        }>
                                        {
                                            this.state.loading ? <AppCardLoaderFields/> :
                                                <Grid container spacing={2} justify="center" alignItems="center" style={{marginBottom:10}}>

                                                    <Grid item xs={12} md={6}>
                                                        <TextField
                                                            margin="normal"
                                                            required
                                                            fullWidth
                                                            name="name"
                                                            color="secondary"
                                                            variant="outlined"
                                                            label={translate('productName')}
                                                            type="text"
                                                            id="name"
                                                            value={fields.name}
                                                            onChange={this.handleChange.bind(this)}
                                                            error={!!errors['name']}
                                                            helperText={errors['name']}
                                                            className={this.classes.inputCtl}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <TextField
                                                            margin="normal"
                                                            required
                                                            fullWidth
                                                            color="secondary"
                                                            name="moq"
                                                            variant="outlined"
                                                            label={translate('moq')}
                                                            placeholder={translate('moqDesc')}
                                                            type="number"
                                                            id="moq"
                                                            value={fields.moq}
                                                            onChange={this.handleChange.bind(this)}
                                                            error={!!errors['moq']}
                                                            helperText={errors['moq']}
                                                            InputProps={{
                                                                startAdornment: (
                                                                    <InputAdornment position="start">
                                                                        <OptionUnitChooser
                                                                            current={moq_unit}
                                                                            onChange={(unit) => this.handleChangeUnit(unit)}/>
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <CurrencyTextField
                                                            variant="outlined"
                                                            fullWidth
                                                            currencySymbol={<CurrencyChooser current={currency}
                                                                                             onChange={(currency) => this.handleChangeCurrency(currency)}/>}
                                                            decimalPlaces={0}
                                                            value={fields.price}
                                                            id="price"
                                                            label={translate('price')}
                                                            name="price"
                                                            color="secondary"
                                                            onChange={this.handleChange.bind(this)}
                                                            error={!!errors['price']}
                                                            helperText={errors['price']}
                                                            className={this.classes.currencyTextField}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <span style={{fontSize:13,marginRight:5}}>{translate('per')}</span>
                                                                        <OptionUnitChooser
                                                                            current={price_unit}
                                                                            onChange={(unit) => this.handleChangePriceUnit(unit)}/>
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <CurrencyTextField
                                                            variant="outlined"
                                                            fullWidth
                                                            currencySymbol={currency}
                                                            decimalPlaces={0}
                                                            color="secondary"
                                                            value={fields.discount_price}
                                                            id="discount_price"
                                                            label={translate('discount_price')}
                                                            name="discount_price"
                                                            onChange={this.handleChange.bind(this)}
                                                            error = {!!errors['discount_price']}
                                                            helperText={errors['discount_price']}
                                                            className={this.classes.currencyTextField}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <span style={{fontSize:13,marginRight:5}}>{translate('per')}</span>
                                                                        <span>{price_unit}</span>
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={12} >
                                                        <FormControl
                                                            fullwidth
                                                            variant="outlined"
                                                            style={{width:"100%"}}
                                                        >
                                                            <InputLabel style={{marginBottom:4}}>{translate('payment_method')}</InputLabel>
                                                            <Select
                                                                variant="outlined"
                                                                value={fields.payment_method}
                                                                multiple="multi_select"
                                                                name="payment_method"
                                                                onChange={this.handleChange.bind(this)}
                                                                label={translate('payment_method')}
                                                                renderValue={(selected) => selected.join(', ')}

                                                            >
                                                                {
                                                                    this.props.setting.payment_terms.map((option,i)=>(
                                                                        <MenuItem key={i} value={option}>
                                                                            <Checkbox checked={fields.payment_method.indexOf(option) > -1}/>
                                                                            <ListItemText primary={option} />
                                                                        </MenuItem>
                                                                    ))
                                                                }
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            variant="outlined"
                                                            multiline
                                                            fullWidth
                                                            name="description"
                                                            label={translate('seoDescription')}
                                                            type="text"
                                                            color="secondary"
                                                            id="description"
                                                            minRows="4"
                                                            className={this.classes.inputCtl}
                                                            value={fields.description}
                                                            onChange={this.handleChange.bind(this)}
                                                            onClick={this.handleClickDescription.bind(this)}
                                                            error={!!errors['description']}
                                                            helperText={errors['description']}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <FieldCounterWithoutBar length={fields.description.length} max={150}/>
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                        }
                                    </AppCard>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <AppCard style={{height:"100%"}}>
                                        {
                                            this.state.loading ? <AppCardLoaderFields/> :
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <LinearCategoryHolder
                                                            outPut={this.handleCategoryHolder.bind(this)}
                                                            inPut={categoriesName}
                                                            required
                                                            error={!!errors['category']}
                                                            editMode={categoriesName.length === 0 ? false : editMode}
                                                        />
                                                        {
                                                            !!errors['category'] ?
                                                                <Typography className="errorText" color="error" variant="body2">
                                                                    {errors['category']}
                                                                </Typography>
                                                                : null
                                                        }
                                                    </Grid>
                                                    {
                                                        !!editMode && !!categoriesName.length &&
                                                        <Grid item xs={12}>
                                                            <Button
                                                                size="small"
                                                                variant="outlined"
                                                                onClick={() => this.changeCategories()}
                                                            >
                                                                {translate('changeCategories')}
                                                            </Button>
                                                        </Grid>
                                                    }

                                                </Grid>
                                        }
                                    </AppCard>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <AppCard style={{height:'100%'}}>
                                        <SubTitle align="left">
                                            {translate('product_details')}
                                        </SubTitle>
                                        {
                                            this.state.loading ? <AppCardLoaderFields/> :
                                                <>
                                                    <CustomFields
                                                        type={"product"}
                                                        position_type="product_details"
                                                        input={this.state.product_details}
                                                        category_id={this.state.parent_category}
                                                        handleCustomFields={(product_details) => this.setState({product_details})}
                                                    />
                                                </>
                                        }
                                    </AppCard>
                                </Grid>
                                {
                                    !!this.state.parent_category  &&
                                    <>
                                        <Grid item xs={12} md={4}>
                                            <AppCard style={{height:'100%'}}>
                                                <SubTitle align="left">
                                                    {translate('essential_details')}
                                                </SubTitle>
                                                {
                                                    this.state.loading ? <AppCardLoaderFields/> :
                                                        <>
                                                            <CustomFields
                                                                type={"product"}
                                                                position_type="essential_details"
                                                                input={this.state.essential_details}
                                                                category_id={this.state.parent_category}
                                                                handleCustomFields={(essential_details) => this.setState({essential_details})}
                                                            />
                                                        </>
                                                }
                                            </AppCard>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <AppCard style={{height:'100%'}}>
                                                <SubTitle align="left">
                                                    {translate('supply_ability')}
                                                </SubTitle>
                                                {
                                                    this.state.loading ? <AppCardLoaderFields/> :
                                                        <>
                                                            <CustomFields
                                                                type={"product"}
                                                                position_type="supply_ability"
                                                                input={this.state.supply_ability}
                                                                category_id={this.state.parent_category}
                                                                handleCustomFields={(supply_ability) => this.setState({supply_ability})}
                                                            />
                                                        </>
                                                }
                                            </AppCard>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <AppCard style={{height:'100%'}}>
                                                <SubTitle align="left">
                                                    {translate('packaging_delivery')}
                                                </SubTitle>
                                                {
                                                    this.state.loading ? <AppCardLoaderFields/> :
                                                        <>
                                                            <CustomFields
                                                                type={"product"}
                                                                position_type="packaging_delivery"
                                                                input={this.state.packaging_delivery}
                                                                category_id={this.state.parent_category}
                                                                handleCustomFields={(packaging_delivery) => this.setState({packaging_delivery})}
                                                            />
                                                        </>
                                                }
                                            </AppCard>
                                        </Grid>
                                    </>
                                }
                                <Grid item xs={12} md={3}>
                                    <AppCard>
                                        {
                                            this.state.loading ? <AppCardLoaderFields/> :
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <SubTitle align="center">{translate('primaryProductImage')}</SubTitle>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <SingleImageChooser
                                                            primary
                                                            onChange={(thumbnail) => this.handleThumbImage(thumbnail)}
                                                            initial={thumbnail}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Typography className={this.classes.condir} style={{textAlign:"center"}}>
                                                            {translate('thumbnailSize')}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                        }
                                    </AppCard>
                                </Grid>
                                <Grid item xs={12} md={9}>
                                    <AppCard style={{height:"100%"}}>
                                        {
                                            this.state.loading ? <AppCardLoaderFields/> :
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <SubTitle align="left">{translate('productGallery')}</SubTitle>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <ImageGallery  onChange={this.handleGalleryChange.bind(this)} initial={gallery}/>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Typography className={this.classes.condir}>
                                                            {translate('productGallerySize')}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                        }
                                    </AppCard>
                                </Grid>
                                {/*<Grid item xs={12}>
                                    <AppCard style={{height:"100%"}}>
                                        {
                                            this.state.loading ? <AppCardLoaderFields/> :
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <SubTitle align="left">{translate('productExamples')}</SubTitle>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <ImageGallery type="image" onChange={this.handleExamplesChange.bind(this)} initial={examples}/>
                                                    </Grid>
                                                </Grid>
                                        }
                                    </AppCard>
                                </Grid>*/}
                                <Grid item xs={12} >
                                    <AppCard style={{height:"100%"}}>
                                        {
                                            this.state.loading ? <AppCardLoaderFields/> :
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <SubTitle align='left'>
                                                            {translate('depotAddress')}
                                                        </SubTitle>
                                                    </Grid>
                                                    {
                                                        !!this.state.has_depot_address &&
                                                        <>
                                                            <Grid item xs={12}>
                                                                <SelectLocationFromMap
                                                                    init_geocode={(!!depot_lat && !!depot_lng) ? {lat:depot_lat,lng:depot_lng} : ""}
                                                                    geocode={(lat,lng) => this.setState({depot_lat:lat,depot_lng:lng})}
                                                                    setAddress={(address) => this.handleDepotAddress(address)}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <TextField
                                                                    variant="outlined"
                                                                    multiline
                                                                    fullWidth
                                                                    name="depot_address"
                                                                    label={translate('depot_address')}
                                                                    type="text"
                                                                    minRows="2"
                                                                    color="secondary"
                                                                    value={fields.depot_address}
                                                                    onChange={this.handleChange.bind(this)}
                                                                    error={!!errors['depot_address']}
                                                                    helperText={errors['depot_address']}
                                                                    InputProps={{
                                                                        endAdornment: (
                                                                            <InputAdornment position="end">
                                                                                <FieldCounterWithoutBar length={fields.depot_address.length} max={150}/>
                                                                            </InputAdornment>
                                                                        ),
                                                                    }}
                                                                />
                                                            </Grid>
                                                        </>
                                                    }
                                                    <Grid item xs={12}>
                                                        <FormControl  component="fieldset">
                                                            <FormControlLabel
                                                                control={<Checkbox checked={!this.state.has_depot_address} onChange={() => this.handleNoDepotLoc()} />}
                                                                label={translate('hasNoDepotLocation')}
                                                            />
                                                            <FormControlLabel
                                                                control={<Checkbox checked={!!this.state.has_depot_address} onChange={() => this.setState({has_depot_address:true})} />}
                                                                label={translate('hasDepotLocation')}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                        }

                                    </AppCard>
                                </Grid>
                                <Grid item xs={12}>
                                    <AppCard>
                                        {
                                            this.state.loading ? <AppCardLoaderFields/> :
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <SubTitle align="left">{translate('productDescription')}</SubTitle>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <YourPlanDoesntSupportThis visible={!hasAccess('has_html')}/>

                                                        <RichEditor
                                                            inputText={editor_input_text}
                                                            ref={this.EditorChild}
                                                            Output={this.handleOutPut.bind(this)}
                                                            input={content.RAW}
                                                            minHeight={300}
                                                            readOnly={!hasAccess('has_html')}
                                                        />
                                                    </Grid>
                                                </Grid>
                                        }
                                    </AppCard>
                                </Grid>
                                <Grid item xs={12}>
                                    <AppCard>
                                        {
                                            this.state.loading ? <AppCardLoaderFields/> :
                                                <>
                                                    <SubTitle align="left">{translate('attachments')}</SubTitle>
                                                    <FilesChooser
                                                        onlyFile
                                                        onChange={this.handleFilesChange.bind(this)}
                                                        initial={files}
                                                    />
                                                </>
                                        }
                                    </AppCard>
                                </Grid>
                                {
                                    (!!user.role.permission.manageShops) &&
                                    <Grid item xs={12}>
                                        <AppCard style={{height:'100%'}}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <SubTitle align="left">{translate('seoDetails')}</SubTitle>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <FormControl component="fieldset">
                                                        <FormControlLabel
                                                            control={<Checkbox checked={this.state.no_index} onChange={() => this.setState({no_index:!this.state.no_index})} />}
                                                            label={translate('makePageNoIndex')}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        margin="normal"
                                                        name="meta_title"
                                                        variant="outlined"
                                                        label={translate('meta_title')}
                                                        value={fields.meta_title}
                                                        onChange={this.handleChange.bind(this)}
                                                        error={!!errors['meta_title']}
                                                        helperText={errors['meta_title']}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </AppCard>
                                    </Grid>
                                }
                                <Grid item xs={12}>
                                    <Alert
                                        style={{marginTop:15}}
                                        severity="info"
                                        action={
                                            <FormControlLabel
                                                style={{marginRight:10}}
                                                control={
                                                    <Switch
                                                        checked={this.state.has_sample}
                                                        onChange={() => this.setState({has_sample: parseInt(this.state.has_sample === 1 ? 0 : 1)})}
                                                        value={this.state.has_sample}
                                                        color="primary"
                                                    />
                                                }
                                                label={this.state.has_sample ? translate('yes') : translate('no')}
                                            />
                                        }
                                    >
                                        {translate('isThisProductHasSample')}
                                    </Alert>
                                </Grid>
                                {
                                    (user.role.type === "admin" && !loading) &&
                                    <Grid item xs={12}>
                                        <Alert
                                            severity="info"
                                            action={
                                                <FormControlLabel
                                                    style={{marginRight:10}}
                                                    control={
                                                        <Switch
                                                            checked={this.state.allowed_comment}
                                                            onChange={() => this.setState({allowed_comment: parseInt(this.state.allowed_comment === 1 ? 0 : 1)})}
                                                            value={this.state.allowed_comment}
                                                            color="primary"
                                                        />
                                                    }
                                                    label={this.state.allowed_comment ? translate('allowed') : translate('not_allowed')}
                                                />
                                            }
                                        >
                                            {translate('allowed_comments')}
                                        </Alert>
                                        <Alert
                                            style={{marginTop:15}}
                                            severity="info"
                                            action={
                                                <FormControlLabel
                                                    style={{marginRight:10}}
                                                    control={
                                                        <Switch
                                                            checked={this.state.is_popular}
                                                            onChange={() => this.setState({is_popular: parseInt(this.state.is_popular === 1 ? 0 : 1)})}
                                                            value={this.state.is_popular}
                                                            color="primary"
                                                        />
                                                    }
                                                    label={this.state.is_popular ? translate('yes') : translate('no')}
                                                />
                                            }
                                        >
                                            {translate('isThisProductPopular')}
                                        </Alert>
                                        <Alert
                                            style={{marginTop:15}}
                                            severity="info"
                                            action={
                                                <FormControlLabel
                                                    style={{marginRight:10}}
                                                    control={
                                                        <Switch
                                                            checked={this.state.verified_sample}
                                                            onChange={() => this.setState({verified_sample: parseInt(this.state.verified_sample === 1 ? 0 : 1)})}
                                                            value={this.state.verified_sample}
                                                            color="primary"
                                                        />
                                                    }
                                                    label={this.state.verified_sample ? translate('yes') : translate('no')}
                                                />
                                            }
                                        >
                                            {translate('isThisProductVerifiedSample')}
                                        </Alert>
                                    </Grid>
                                }
                                {
                                    (!! user.role.permission.manageShops && !!editMode && !this.state.loading) &&
                                    <Grid item xs={12}>
                                        <UserCard title={translate('creator')} id={owner.id}/>
                                    </Grid>
                                }
                                {
                                    (!!user.role.permission.manageShops  && !editMode) &&
                                    <Grid item xs={12}>
                                        <AppCard style={{height:'100%'}}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <SubTitle align="left">{translate('relatedUser')}</SubTitle>
                                                </Grid>
                                                {
                                                    this.state.loading ? <AppCardLoaderFields/> :
                                                        <Grid item xs={12}>
                                                            <ProductUserChooser
                                                                outPut={(res) => this.setState({
                                                                    related_user:res
                                                                })
                                                                }
                                                            />
                                                        </Grid>
                                                }
                                            </Grid>
                                        </AppCard>
                                    </Grid>
                                }

                                <Grid item xs={12}>
                                    <LoadingButton
                                        variant="contained"
                                        color="primary"
                                        style={{minWidth:200}}
                                        onClick = {this.handleSubmit.bind(this)}
                                        loading = {SubmitLoading}
                                    >
                                        {editMode ? translate('editProduct') :  translate('makeProduct')}
                                    </LoadingButton>
                                </Grid>
                                <Grid item xs={12}>
                                    {
                                        !!editMode ?
                                            user.id === this.state.owner_id && !user.role.permission.manageAdvertisements && !this.state.requested_to_delete &&
                                            <Button
                                                startIcon={<DeleteRoundedIcon/>}
                                                style={{color:"#ff5451"}}
                                                onClick={() => this.handleRequestToDelete()}
                                            >
                                                {translate('requestToDeleteProduct')}
                                            </Button>
                                            : null
                                    }
                                    {
                                        !!editMode ?
                                            !!user.role.permission.manageAdvertisements &&
                                            <GetConfirmationText
                                                variant="outlined"
                                                style={{color: "#f44336",backgroundColor:"#fff"}}
                                                tooltip={translate('removeProduct')}
                                                handleAction={() => this.handleDeleteItem()}
                                            >
                                                {translate('removeProduct')}
                                            </GetConfirmationText>
                                            : null
                                    }
                                </Grid>
                            </Grid>
                        </Grid>

                    </Grid>

                }
            </AppAnimate>

        );
    }
}

const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user,
    primary_shop: state.primary_shop,
    locale: state.locale,
    setting: state.setting,
});
export default withRouter(withSnackbar(withStyles(Styles)(connect(mstp)(MakeProduct))));